.spacer {
  margin:10px 0px;
}

.file-uploader {
  background-color: rgb(243, 243, 243);
  padding: 40px;
  border-radius: 10px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}