body {
  background-color: #ffffff;
  min-height: 100vh;
}

* {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  background-size: cover;
  background-position-x:center;
  background-position-y:center;
  background-position-y:calc(90%);
  /*background-position-y:calc(10% - 50px);*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row {
  display: flex;
  flex-direction: row;
}

a {
  color:black;
}


.text-page {
  padding-top:50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom:50px;
}

.text-page img {
  width:400px;
  margin-bottom:40px;
}

.text-page .text {
  width:80%;
  max-width: 800px;
  display:flex;
  flex-direction: column;
  margin-bottom:20px;
  color:#222;
}

.text-page .Social {
  justify-content: center;
}

.text-page .Social a {
  margin:10px;
}

@media screen and (max-width: 700px) {    
  .text-page  {
    padding-top:15px;
  }
}